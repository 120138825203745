module.exports = {
    deviceWidth: {
        largeScreenStart: 1024,
        tabWidthStart: 768,
        tabWidthEnd: 1023,
        smallScreenEnd: 767,
    },
    headerAndFooter:"https://images.ctfassets.net/8g0fievzqg8k/7AIuyDbgEXmEe6VUkQNemS/2fb41e9365c943811791d0acedd90450/whisper-logo_2x.png",
    mobileHeaderAndFooter : "https://images.ctfassets.net/8g0fievzqg8k/5aKYJ085iWxwltfor4Svtv/2cfbfd13dd4945fc2a397527691a351d/whisper-logo.png",
    myrepiLogo:"https://images.ctfassets.net/8g0fievzqg8k/5VihwVa9CBXNNApKW3xsVq/e594106db9bf5eca664379c943d78d18/Myrepi-logo_plus-P_G_2312_177_49px.png",
    hamburgerIcon:"https://images.ctfassets.net/8g0fievzqg8k/7yu4IJD6g4NuBZMk1jz1Xs/b81aa3e00b190b5527372754b012c5f5/hamburger.PNG",
    buyProducts : "https://images.ctfassets.net/8g0fievzqg8k/4SGOSyxFyxfUi6gR5lSV92/594cee328ab37a5c8809d81282b85c58/ususaraanshin_BUY_PCSP.jpg?h=250",
    popup : "https://images.ctfassets.net/8g0fievzqg8k/tFxWAmVskGW2jvoeLERgN/6c6fefd767e6f5e57428262bf21e494f/amazoncouponbanner220429_POPUP_PC.jpg",
    AmazonCoupon : "https://images.ctfassets.net/8g0fievzqg8k/1hu2LoxyJvGwlHHIpRYtmV/32e578b6909b08245798128b920f037e/amazoncouponbanner_triangle_PCSP.png",
    icon1 : "https://images.ctfassets.net/8g0fievzqg8k/2MSmg58oxdVWWLBXbTonQY/4021a09c83ba0e1564088eabad550173/surveyQuestion1.png",
    icon2 : "https://images.ctfassets.net/8g0fievzqg8k/f1oH3lJYdsUOyUP1HpnM4/680e193a5ffc22abd30c762066ecd267/surveyQuestion2.png",
    icon3 : "https://images.ctfassets.net/8g0fievzqg8k/LR6oCfOUjRHsTKMTRLrz5/9496870f6ac05e9ad51b86f848dee365/surveyQuestion3.png",
    icon4 : "https://images.ctfassets.net/8g0fievzqg8k/4hPxhTyNqfixN9CgdX5L7w/96462675aefe72c315868b47f2fcd36c/question4.png",
    registration1 : "https://images.ctfassets.net/8g0fievzqg8k/2gD95CbGTPrNdW6Kw9Ld1c/38550da76d0573eb772a7e499ae31602/pc_top_body02_img01.png",
    registration2 : "https://images.ctfassets.net/8g0fievzqg8k/4xtHiPx52Sy4mQ00VaLH0Y/0737a38c77d432a84cf2f977d7fd1e08/pc_top_body02_img02.png",
    registration3 : "https://images.ctfassets.net/8g0fievzqg8k/3FCC0tH3w1piUoK24gJKBV/d74cd03850cc420a512bef238812026d/pc_top_body02_img03.png",
    registartion4 : "https://images.ctfassets.net/8g0fievzqg8k/13GEPbtYxZyKfCLP7OS8iU/e7daea078dc978fb21dce864d10e2272/pc_top_body03_no01.png",
    registration5 : "https://images.ctfassets.net/8g0fievzqg8k/4bMfcHhZopOglHr3cNtPFY/ab28706a4e3afaab96973791ddc038ad/pc_top_body03_no02.png",
    registration6 : "https://images.ctfassets.net/8g0fievzqg8k/3OfJ8rJKE0bT9qcXqz1zGT/fb0ae4cf260cacba2c4be5a9a53f4c24/pc_top_body03_no03.png",
    liveImgPath: "https://cdn.contentful.com/spaces/8g0fievzqg8k",
    liveUrl: "www.whisper.jp",
    previewImgPath: "https://preview.contentful.com/spaces/8g0fievzqg8k/environments/cloudinary-migration/",
    headerStickyDetails:{
        headerId: "header",
        stickyClassName:"sticky",
        navStickyClassName:"navSticky"
    },
    regUrl : "https://www.myrepi.com/registration/",
    incorrectPassword : "Incorrect username or password. Please try again.",
    jpText : "パスワードが違います",
    multipleAttempts : "Too many failed login attempts. Please try again later.",
    jpMultipleText : "ログイン試行の失敗が多すぎます。あなたのアカウントは一時的にロックされています"


}